import * as React from "react"

// layout
import Layout from "../layout"
import Hero from "../components/hero"
import Services from "../components/services"
import Info from "../components/info"
import Steps from "../components/steps"
import App from "../components/app"
import Carousel from "../components/carousel"

const IndexPage = () => {
  return (
    <Layout>
        <Hero />
        <Carousel />
        <Services />
        <Steps />
        <Info />
        <App />
    </Layout>
  )
}

export default IndexPage
