import * as React from "react";

import { StaticImage } from 'gatsby-plugin-image';

import '../styles/info.scss';

import {Box} from "@mui/material"

const Info = () => {

    return (
        <Box 
            className="section-info"
            sx={{
                px: {
                    xs: "0px",
                    sm: "20px"
                }
            }}
        >

            <StaticImage src="../images/info/1.png" className="img-info-1" alt="¿Sigues comparando créditos Hipotecarios?" />
            <div className="wrapper-info-1"> 
                <h4>¿Sigues comparando créditos Hipotecarios?</h4>
               
                <p className="text">Te ayudamos a elegir el mejor credito hipotecario ¡sin costo!</p>
                <p className="text">Nosotros nos encargaremos de todo el tramite. Gestionamos el crédito frente a las diversas instituciones bancarias sin costo alguno, proporcionando comodidad, profesionalismo, eficiencia y rapidez.</p>
                <p className="text">Ahorra miles de cientos de pesos al elegir el banco correcto. El cliente estará seguro de que le brindaremos una asesoría integral que le ayudará a elegir la mejor opción de acuerdo a sus necesidades, logrando con ello reducir costos y evitar gastos innecesarios.</p>
            </div>

            <StaticImage src="../images/info/2.png" className="img-info-2" alt="Nosotros lo hacemos por ti." />
            <div className="wrapper-info-2"> 
                <h4>Nosotros lo hacemos por ti.</h4>
               
                <p className="text">Brindamos la asesoría de mayor calidad para que elijas  el mejor crédito hipotecario de acuerdo a tus necesidades,  ahorrando costos innecesarios.</p>
                <p className="text">Trabajamos con entidades bancarias, con lo que te garantizamos profesionalismo, eficiencia y rapidez.</p>
                <p className="text">El cliente estará seguro de que le brindaremos una asesoría integral que le ayudará a elegir la mejor opción de acuerdo a sus necesidades, logrando con ello reducir costos y evitar gastos innecesarios.</p>
            </div>

            <StaticImage src="../images/info/3.png" className="img-info-3" alt="Hemos ayudado a mas de 40,000 familias mexicanas." />
            <div className="wrapper-info-3"> 
                <h4>Hemos ayudado a mas de 40,000 familias mexicanas.</h4>
               
                <p className="text">A lo largo de nuestra participación en México, hemos logrado colocar miles de millones de pesos en hipotecas, cumpliendo así el sueño de muchas familias mexicanas.</p>
            </div>
        </Box>
    )
}

export default Info
