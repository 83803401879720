import * as React from "react"

import { StaticImage } from 'gatsby-plugin-image';

import '../styles/services.scss'

const Services = () => {

    return (
        <div className="wrapper-services"> 
            <section className="services">
                <h3 className="title-services">Nuestros servicios no tienen ningun costo para ti</h3>

                <StaticImage src="../images/services/1.png" className="img-service-1" alt="Adquisición de vivienda" />
                <div className="wrapper-service-1"> 
                    <h4>Adquisición de vivienda</h4>
                    <p className="wrapper-text">La especialidad de la casa!. Si vas a comprar tu casa, nosotros somos tu mejor aliado. Te llevaremos de la mano en todo el proceso. Elegiremos el mejor banco y el mejor producto financiero en el mercado para ti.</p>
                </div>

                <StaticImage src="../images/services/2.png" className="img-service-2" alt="Cambio de hipoteca" />
                <div className="wrapper-service-2"> 
                    <h4>Cambio de hipoteca</h4>
                    <p className="wrapper-text">¿Estas pagando una hipoteca? Mándanos tu último estado de cuenta y te diremos si el crédito que tienes es el ideal, o bien, si podemos sustituirlo haciéndote ahorrar cientos de miles de pesos. Te haremos un estudio sin ningún costo y sin ningún compromiso.</p>
                </div>


                <StaticImage src="../images/services/3.png" className="img-service-3" alt="Crédito para Construcción o Remodelación" />
                <div className="wrapper-service-3"> 
                    <h4>Crédito para Construcción o Remodelación</h4>
                    <p className="wrapper-text">¿Lo tuyo es la Construcción? Tenemos el crédito ideal para ti, ya sea que quieras construir en terreno propio, o bien que quieras remodelar la casa en la que vives. Nuestros expertos hipotecarios te ayudarán a elegir la mejor opción de financiamiento.</p>
                </div>

                <StaticImage src="../images/services/4.png" className="img-service-4" alt="Crédito de liquidez" />
                <div className="wrapper-service-4"> 
                    <h4>Crédito de liquidez</h4>
                    <p className="wrapper-text">¿Necesitas un financiamiento y tienes una casa que puedes dejar en garantía? El crédito hipotecario de liquidez es la opción más barata del mercado. No importa si tu casa está hipotecada, contáctanos y nos encargaremos de todo el trámite de principio a fin.</p>
                </div>

                <StaticImage src="../images/services/5.png" className="img-service-5" alt="Adquisición de terreno urbano" />
                <div className="wrapper-service-5"> 
                    <h4>Adquisición de terreno urbano</h4>
                    <p className="wrapper-text">Un terreno urbano puede ser la mejor opción de inversión para tí. Acércate a Creditaria. Nuestros expertos hipotecarios buscarán el mejor financiamiento para ti.</p>
                </div>

                <StaticImage src="../images/services/6.png" className="img-service-6" alt="Crédito Pyme" />
                <div className="wrapper-service-6"> 
                    <h4>Crédito Pyme</h4>
                    <p className="wrapper-text">¿Has pensado en comprar un local?, ¿una nave industrial?, ¿o cualquier inmueble de uso comercial? Tenemos la mejor opción para ti. Nuestros expertos hipotecarios te asesorarán en todo el proceso.</p>
                </div>
            </section>
        </div>
    )
}

export default Services
